<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ placeholder }}
        <span v-if="required">*</span>
      </div>
      <div class="select-calendar">
        <input
          type="hidden"
          v-model="localValue"
          @change="onInput"
        >
        <div class="select-calendar__inner">
          <div class="select-calendar__item select-calendar__day">
            <esmp-select
              placeholder="День"
              v-model="localDayValue"
            >
              <esmp-select-option
                v-for="item in days"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="select-calendar__item select-calendar__month">
            <esmp-select
              placeholder="Месяц"
              v-model="localMonthValue"
            >
              <esmp-select-option
                v-for="item in months"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="select-calendar__item select-calendar__year">
            <esmp-select
              placeholder="Год"
              v-model="localYearValue"
            >
              <esmp-select-option
                v-for="item in years"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import { AVAILABLE_DATES_LIST } from '@/constants/calendar';

const YEAR_COUNT = 5;
const DAYS_IN_MONTH = 31;

export default {
  name: 'HmCalendar',
  mixins: [uid],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Object, Array],
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    defaultDate: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    datesToShowType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dayValue: null,
      monthValue: null,
      yearValue: null,
      months: [
        {
          id: '01',
          name: 'Января',
        },
        {
          id: '02',
          name: 'Февраля',
        },
        {
          id: '03',
          name: 'Марта',
        },
        {
          id: '04',
          name: 'Апреля',
        },
        {
          id: '05',
          name: 'Мая',
        },
        {
          id: '06',
          name: 'Июня',
        },
        {
          id: '07',
          name: 'Июля',
        },
        {
          id: '08',
          name: 'Августа',
        },
        {
          id: '09',
          name: 'Сентября',
        },
        {
          id: '10',
          name: 'Октября',
        },
        {
          id: '11',
          name: 'Ноября',
        },
        {
          id: '12',
          name: 'Декабря',
        },
      ],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    isPast() {
      return this.datesToShowType === AVAILABLE_DATES_LIST.onlyPastAndToday.value
        || this.datesToShowType === AVAILABLE_DATES_LIST.onlyPast.value;
    },
    isFuture() {
      return this.datesToShowType === AVAILABLE_DATES_LIST.onlyFutureAndToday.value
        || this.datesToShowType === AVAILABLE_DATES_LIST.onlyFuture.value;
    },
    localDayValue: {
      get() {
        return this.dayValue?.id;
      },
      set(dayId) {
        this.dayValue = this.days.find((d) => d.id === dayId) || null;
        this.emitChanges();
      },
    },
    localMonthValue: {
      get() {
        return this.monthValue?.id;
      },
      set(monthId) {
        this.checkDays(this.getDaysCount(monthId));
        this.monthValue = this.months.find((m) => m.id === monthId);
        this.emitChanges();
      },
    },
    localYearValue: {
      get() {
        return this.yearValue?.id;
      },
      set(yearId) {
        this.yearValue = this.years.find((y) => y.id === yearId);
        this.emitChanges();
      },
    },
    daysCount() {
      return this.getDaysCount(this.monthValue?.id);
    },
    days() {
      const days = [];
      for (let index = 1; index <= this.daysCount; index += 1) {
        const day = {
          id: this.zeroNumberStr(index),
          name: this.zeroNumberStr(index),
        };
        days.push(day);
      }

      return days;
    },
    years() {
      const years = [];
      const now = new Date();
      const currentYear = now.getFullYear();

      let offset = Math.ceil(YEAR_COUNT / -2); // default

      if (this.isPast) {
        offset = (YEAR_COUNT - 1) * -1;
      } else if (this.isFuture) {
        offset = 0;
      }
      const startYear = currentYear + offset;
      for (let step = 0; step < YEAR_COUNT; step += 1) {
        const year = `${startYear + step}`;
        const yearsItem = {
          id: year,
          name: year,
        };
        years.push(yearsItem);
      }

      return years;
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    zeroNumberStr(num) {
      return num > 9 ? `${num}` : `0${num}`;
    },
    emitChanges() {
      this.$nextTick(() => {
        this.checkDays();
        const value = this.getVal();
        if (value) {
          this.localValue = value;
        }
      });
    },
    checkDays(lastDay) {
      if (this.localDayValue && (Number(this.localDayValue) > lastDay)) {
        this.localDayValue = this.zeroNumberStr(lastDay);
      }
    },
    getDaysCount(monthNumberByStr) {
      const today = new Date();
      if (monthNumberByStr) {
        const year = this.yearValue && this.yearValue.id
          ? this.yearValue.id
          : today.getFullYear();
        // Последний день месяца = кол-во дней в этом месяце
        return new Date(Number(year), Number(monthNumberByStr), 0).getDate();
      }
      return DAYS_IN_MONTH;
    },
    getVal() {
      if (!this.dayValue || !this.monthValue || !this.yearValue) return null;
      return `${this.dayValue.id}.${this.monthValue.id}.${this.yearValue.id}`;
    },
    setVals(dateStr) {
      const arr = dateStr.split('.');
      if (arr && arr.length) {
        this.dayValue = this.days.find((item) => item.id === arr[0]);
        this.monthValue = this.months.find((item) => item.id === arr[1]);
        this.yearValue = this.years.find((item) => item.id === arr[2]);
      }
    },
  },
  watch: {
    value(val) {
      if (val) this.setVals(val);
    },
  },
  mounted() {
    if (this.value) this.setVals(this.value);
    else if (this.defaultDate) this.setVals(this.defaultDate);
  },
};
</script>

<style lang="scss" scoped>
.select-calendar__label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}
.select-calendar__inner {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.select-calendar__item:not(:last-child) {
  margin-right: 5px;
}
.select-calendar__day {
  width: 95px;
}
.select-calendar__month {
  width: 135px;
}
.select-calendar__year {
  width: 115px;
}
</style>
